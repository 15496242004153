import React from "react"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Helmet from "react-helmet";
import { Button } from 'antd';

const ModuleCalc = () => {
    const [a, setA] = React.useState(92)
    const [b, setB] = React.useState(56)
    const [l, setL] = React.useState(100)
    const [m, setM] = React.useState(100)

    function isValidInput() {

        if (a == null || b == null || l == null || m == null) {
            return false
        }

        if (a <= 0 || b <= 0 || l <= 0 || m <= 0) {
            return false
        }

        return true
    }

    function isAnyOneNull() {
        if (a == null || b == null || l == null || m == null) {
            return true
        }

        return false
    }

    const og = {
        title: '線上印刷模數計算器 | 名片模數、模數計算、印後加工、卡片模數',
        description: '印刷模數計算：min(⌈l/a⌉ * ⌈m/b⌉, ⌈l/b⌉ * ⌈m/a⌉)',
        url: BASE_URL + '/module-calc/',
        image: '/images/module-calc.png',
    }

    function ModuleNum(a, b, l, m) {
        const r1 = Math.ceil(l / a) * Math.ceil(m / b)
        const r2 = Math.ceil(l / b) * Math.ceil(m / a)

        return Math.min(r1, r2)
    }

    return (
        <div style={{ width: '100vw', height: '99vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: '#eeeeee', }}>
            <SEO og={og} />

            <div style={{ width: '100vw', height: '99vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F8F6F4', maxWidth: 600 }}>

                <div style={{ height: '99vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: '#F8F6F4', maxWidth: 600 }}>

                    <a href='/' aria-label="Sam Huang" target='_blank'><img src='/logo5.png' style={{ width: 80, height: 80, marginBottom: 10, }} /></a>

                    <h1 style={{ backgroundColor: '#CCD5AE', padding: '5px 10px', borderRadius: 5 }}>印刷模數計算器</h1>

                    <p style={{ fontSize: 16, backgroundColor: '#FEFAE0', padding: '5px 10px', borderRadius: 5 }}>
                        公式：<br />模數 = min(⌈l/a⌉ * ⌈m/b⌉, ⌈l/b⌉ * ⌈m/a⌉)
                    </p>

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', }}>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'cneter', marginTop: 20, color: '#1A4870' }}>
                            一模尺寸：&nbsp;&nbsp;&nbsp;
                            <input type='number' style={{ width: 50, height: 20 }} value={a} onChange={e => setA(e.target.value)} placeholder='長(a)' />&nbsp;mm
                            &nbsp;x&nbsp;
                            <input type='number' style={{ width: 50, height: 20 }} value={b} onChange={e => setB(e.target.value)} placeholder='寬(b)' />&nbsp;mm
                        </div>
                        <p style={{ fontSize: '0.7rem', fontWeight: 'bold', width: '100%', maxWidth: 300, minWidth: 200 }}>* 製造生產時的基本尺寸，比如常見名片(含出血)的一模就是92*56mm</p>
                        <br />
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'cneter', marginTop: 10, color: '#1F316F' }}>
                            印刷品尺寸：&nbsp;&nbsp;&nbsp;
                            <input type='number' style={{ width: 50, height: 20 }} value={l} onChange={e => setL(e.target.value)} placeholder='長(l)' />&nbsp;mm
                            &nbsp;x&nbsp;
                            <input type='number' style={{ width: 50, height: 20 }} value={m} onChange={e => setM(e.target.value)} placeholder='寬(m)' />&nbsp;mm
                        </div>
                    </div>

                    <br />

                    {!isValidInput() && <h3 style={{ borderRadius: 5, padding: 10, color: '#C75B7A' }}>➜&nbsp;Error ! 請輸入正確資料以計算模數</h3>}

                    {isValidInput() && <h3>➜ 計算後模數 = {isValidInput() ? <div style={{ display: 'inline-block', backgroundColor: '#C9EEFF', padding: '5px 10px', borderRadius: 5, color: isValidInput() ? 'black' : '#387F39' }}>{ModuleNum(a, b, l, m) + ' 模'}</div> : '(請輸入資料或檢查輸入值)'} </h3>}



                    <br />
                    <br />
                    <hr style={{ width: '80%', hieght: 2, backgroundColor: 'grey' }} />


                    <div style={{ width: '100%', minWidth: 200, maxWidth: 350, }}>


                        <p>參考資訊：<a rel="noreferrer" href='https://lixiangprint0327.medium.com/%E4%BB%80%E9%BA%BC%E6%98%AF%E5%8D%B0%E5%88%B7%E7%9A%84%E6%A8%A1%E6%95%B8-%E5%A6%82%E4%BD%95%E8%A8%88%E7%AE%97-33fd39872a3b' target='_blank'>什麼是印刷的模數？如何計算？</a> </p>
                        <div >
                            <a href='https://www.revtel.tech/' target='_blank' rel="noreferrer" style={{ textDecoration: 'none', color: '#F26D27', fontWeight: 'bold' }}>忻旅科技</a>
                            &nbsp;&nbsp;
                            {/*
            <a href='https://www.eculture.tech/' target='_blank' rel="noreferrer" style={{textDecoration:'none', color:'#7AA874', fontWeight:'bold'}}>奕果雲端</a>
            &nbsp;&nbsp;
             */}
                            <a href='https://www.sam-huang.info/' target='_blank' rel="noreferrer" style={{ textDecoration: 'none', color: 'blue', fontWeight: 'bold' }}>Sam Huang</a>
                        </div>
                    </div>
                    <br /><br />
                </div>

            </div>
        </div>
    )
}

const BASE_URL = 'https://www.sam-huang.info'
const SEO = ({ og }) => {
    const seo = {
        title: og.title,
        image: BASE_URL + (og.image || '/images/sky.png'),
        description: og.description || ' ',
        url: og.url || BASE_URL
    }
    return (
        <Helmet

            title={seo.title}
            meta={[
                {
                    name: `description`,
                    content: seo.description
                },
                {
                    property: `image`,
                    content: seo.image
                },
                {
                    property: `name`,
                    content: seo.title
                },
                {
                    property: `og:title`,
                    content: seo.title
                },
                {
                    property: `og:url`,
                    content: seo.url
                },
                {
                    property: `og:site_name`,
                    content: '線上印刷模數計算器 ｜ 名片、酷卡、...',
                },
                {
                    property: `og:image`,
                    content: seo.image
                },
                {
                    property: `og:description`,
                    content: seo.description
                },
                {
                    property: 'og:url',
                    content: seo.url,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:image`,
                    content: seo.image
                },
                {
                    name: `twitter:creator`,
                    content: 'Sam Huang,黃奕翔'
                },
                {
                    name: `twitter:title`,
                    content: seo.title
                },
                {
                    name: `twitter:description`,
                    content: seo.description
                },
                {
                    property: 'keywords',
                    content: 'Sam Huang, Sam, sailplaneTW, 黃奕翔, 賣飛機的, 忻旅科技, Revtel, RevtelTech, 奕果雲端, Eculture, EcultureTech, React, React Native, Inigma, Tel25, Tel-U, 加密鏈, 軟體開發, 軟體顧問, 新創營運, web3, 區塊鏈',
                },
            ]}
        />
    )
}



export default ModuleCalc
